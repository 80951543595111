import axios from "axios";

const instance = axios.create({
	baseURL: process.env.VUE_APP_API_ROUTE_ADDRESS,
	headers: {
		"Content-Type": "application/json",
		"X-API-Key": process.env.VUE_APP_X_API_KEY
	},
});
export default instance;
