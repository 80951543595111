import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@/stores/authStore"
import {useDesignStore} from "@/stores/designStore";
import VueBodyClass from 'vue-body-class';
import {SETTINGS_CONFIG} from "@/config/settings.config";


const routes = [
	// PUBLIC ROUTES BEGIN HERE:
	{
		path: "/",
		name: "Home",
		meta: {bodyClass: "home", title: "Home"},
		component: () => import("../views/Home.vue"),
	},
	{
		path: "/joining-friends",
		name: "BecomeAFriend",
		meta: {bodyClass: "joining-friends", title: "Become a Friend"},
		component: () => import("../views/BecomeAFriend.vue"),
	},
	{
		path: "/faqs",
		name: "FAQs",
		meta: {bodyClass: "faqs", title: "Frequently Asked Questions (FAQs)"},
		props: true,
		component: () => import("../views/Faqs.vue"),
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		meta: {bodyClass: "forgot-password", title: "Forgot Password"},
		component: () => import("../views/ForgotPassword.vue"),
	},
	{
		path: "/invite-others",
		name: "InviteOthers",
		meta: {bodyClass: "invite-others", title: "Invite Others"},
		component: () => import("../views/InviteOthers.vue"),
	},
	{
		path: "/learn-about-friends",
		name: "LearnAboutFriends",
		meta: {bodyClass: "learn-about-friends", title: "Learn about Friends"},
		component: () => import("../views/LearnAboutFriends.vue"),
	},
	{
		path: "/learn-about-gideons",
		name: "LearnAboutGideons",
		meta: {bodyClass: "learn-about-gideons", title: "Learn about Gideons"},
		component: () => import("../views/LearnAboutGideons.vue"),
	},
	{
		path: "/privacy-policy",
		name: "PrivacyPolicy",
		meta: {bodyClass: "privacy-policy", title: "Privacy Policy"},
		component: () => import("../views/PrivacyPolicy.vue"),
	},
	{
		path: "/reset-password/:id",
		name: "ResetPassword",
		meta: {bodyClass: "reset-password", title: "Reset Password"},
		component: () => import("../views/ResetPassword.vue"),
	},
	{
		path: "/registerexisting",
		alias: "/register-existing",
		name: "RegisterExisting",
		meta: {bodyClass: "register-existing", title: "Register Existing User"},
		component: () => import("../views/RegisterExisting.vue"),
	},
	// PUBLIC ROUTES END HERE.

	{
		path: "/friend",
		name: "Friend",
		meta: {bodyClass: "friend", title: "Friend Home"},
		component: () => import("../views/Friend.vue"),
	},
	{
		path: "/ministry-update",
		name: "Ministry",
		meta: {bodyClass: "ministry-update", title: "Ministry Updates"},
		component: () => import("../views/Ministry.vue"),
	},
	{
		path: "/ministry-update/:id",
		name: "MinistryUpdate",
		meta: {bodyClass: "ministry-update", title: "Ministry Update"},
		component: () => import("../views/MinistryUpdate.vue"),
	},
	{
		path: "/interior",
		name: "Interior",
		meta: {bodyClass: "interior"},
		component: () => import("../views/Interior.vue"),
	},
	{
		path: "/giving",
		name: "GivingPage",
		meta: {bodyClass: "giving", title: "Giving"},
		component: () => import("../views/GivingPage.vue"),
	},
	{
		path: "/account-information",
		name: "AccountManagement",
		meta: {bodyClass: "account-information", title: "Account Management"},
		component: () => import("../views/AccountManagement.vue"),
	},
	{
		path: "/store",
		name: "Store",
		meta: {bodyClass: "store-main", title: "Store"},
		component: () => import("../views/Store.vue"),
	},
	{
		path: "/store/:id",
		name: "StoreProducts",
		meta: {bodyClass: "store-details", title: "Product Details"},
		component: () => import("../views/StoreProducts.vue"),
	},
	{
		path: "/cart",
		name: "Cart",
		meta: {bodyClass: "cart", title: "Cart"},
		component: () => import("../views/Cart.vue"),
	},
	{
		path: "/checkout",
		name: "Checkout",
		meta: { bodyClass: "store-checkout", title: "Checkout"},
		component: () => import("../views/StoreCheckout.vue"),
	},
	{
		path: "/prayer",
		name: "Prayer",
		meta: {bodyClass: "prayer", title: "Prayer"},
		component: () => import("../views/Prayer.vue"),
	},
	{
		path: "/witness",
		name: "Witness",
		meta: {bodyClass: "witness", title: "Witness"},
		component: () => import("../views/Witness.vue"),
	},
	{
		path: "/logout",
		name: "Logout",
		meta: {bodyClass: "logout", title: "Logout"},
		component: () => import("../views/Home.vue"),
	},
	// CUSTOM ROUTES
	{
		path: "/join",
		name: "Join",
		meta: {bodyClass: "joining-friends", title: "Become a Friend"},
		component: () => import("../views/campaign/BecomeAFriend.vue"),
	},
	{
		path: "/2025",
		name: "2025",
		meta: {bodyClass: "joining-friends", title: "Become a Friend"},
		component: () => import("../views/campaign/BecomeAFriend.vue"),
	},
	{
		path: "/signup",
		name: "Signup",
		meta: {bodyClass: "joining-friends", title: "Become a Friend"},
		component: () => import("../views/campaign/BecomeAFriend.vue"),
	},
	{
		path: "/campaign-giving",
		name: "CampaignGivingPage",
		meta: {bodyClass: "giving", title: "Giving"},
		component: () => import("../views/campaign/GivingPage.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior: function (to, from, savedPosition) {
		if (to && to.params && to.params.openQuestion === 'return-policy') {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve({el: "#collapse-store-9", behavior: 'smooth', top: 145,})
				}, 500)
			})
		} else if (to.hash) {
			return {
				el: to.hash,
				behavior: 'smooth',
			}
		} else {
			return {
				top: 0
			}
		}
	}
})

const vueBodyClass = new VueBodyClass(routes);

const basePageTitle = 'Friends of Gideons'

router.beforeEach(async (to, from, next) => {
	const detailedPageTitle = to.meta.title ? ` - ${to.meta.title}` : ''
	document.title = basePageTitle + detailedPageTitle;

	const authStore = useAuthStore()
	const designStore = useDesignStore()
	const publicPages = SETTINGS_CONFIG.routerPublicPages()

	let authRequired = !publicPages[to.name]
	let isLoggedIn = authStore.isLoggedIn || false

	// Check local storage for existing auth
	if (await authStore.checkLocalStorageForAuth()) {
		var successLogin = await authStore.setUserToLoggedIn()

		if (successLogin) isLoggedIn = true
	}

	// Apply correct layout based on logged-in status ( DefaultLayout = Not logged in  |  PageLayout = Logged  In )
	if (isLoggedIn) {
		to.meta.layout = 'PageLayout'
	} else {
		to.meta.layout = 'DefaultLayout'
	}

	if (authRequired && !isLoggedIn) {
		return next('/')
	}

	if (
		!authStore.isUSAMember
		&& (to.name === 'Store' || to.name === 'StoreProducts' || to.name === 'Cart' || to.name === 'Checkout')
	) {
		return next('/friend') // Only USA Members can see the Store and related pages
	}

	if (to.name === 'Store' || to.name === 'StoreProducts') {
		// only visible on 'Store' and 'Product Details' pages
		designStore.setIsCartVisible(true)
	} else {
		designStore.setIsCartVisible(false)
	}

	vueBodyClass.guard(to, next)
})


export default router
